<template>
  <v-container>
    <v-toolbar flat color="white" class="mt-1">
      <v-toolbar-title>
        <span class="headline"> Upload Your Clients </span>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-title>
        <v-btn
          class="button-primary px-2"
          href="../static/JOBS_sample_client_upload.csv">
          <v-icon>mdi-download</v-icon>
          Get Sample File
        </v-btn>
      </v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <v-form>
          <v-file-input
            cols="12"
            label="Client File to Upload (.csv)"
            :loading="isFileUploading"
            accept=".csv"
            :truncate-length="1000"
            @focus="onFocus"
            @blur="onBlur"
            @change="handleFile" />
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          v-show="isFileUploaded"
          class="button-primary px-2"
          @click="importRecords()">
          <v-icon>mdi-import</v-icon>
          Import Records
        </v-btn>
      </v-col>
      <v-col style="line-height: 36px">
        Total Client Records in File: {{ countOfRecordsUploaded }}
      </v-col>
      <v-col style="line-height: 36px">
        Records Successfully Imported: {{ countOfRecordsImported }}
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        <v-btn class="button-tertiary" @click="deleteBadRecords">
          <v-icon style="color: white"> mdi-delete-variant </v-icon>Remove Bad
          Records
        </v-btn>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="QuickSearch"
          single-line
          hide-details />
      </v-card-title>
      <v-data-table
        :search="search"
        :headers="headers"
        :items="clients"
        class="elevation-1"
        :items-per-page="-1">
        <template v-slot:no-data>
          <div style="width: 100%; text-align: center">
            Upload a Client CSV file to get started
          </div>
        </template>

        <template v-slot:item="props">
          <tr
            :class="{
              duplicate: props.item.isDuplicate,
              duplicated: props.item.isDuplicated,
              imported: props.item.isImported,
              duplicateOnServer: props.item.isDuplicateOnServer,
            }">
            <td>
              <v-icon
                v-show="
                  !props.item.isImported &&
                  !props.item.isDuplicateOnServer &&
                  !props.item.isDuplicate &&
                  !props.item.importFailed
                "
                style="color: grey"
                title="Ready to Upload">
                mdi-cloud-upload
              </v-icon>
              <v-icon
                v-show="
                  props.item.isDuplicate &&
                  !props.item.isImported &&
                  !props.item.isDuplicateOnServer
                "
                style="color: red"
                title="Duplicate Found">
                mdi-block-helper
              </v-icon>
              <v-icon
                v-show="
                  props.item.isImported &&
                  !props.item.isDuplicateOnServer &&
                  !props.item.isDuplicate
                "
                style="color: green"
                title="Successfully Uploaded">
                mdi-cloud-check
              </v-icon>
              <v-icon
                v-show="props.item.importFailed && !props.item.isImported"
                style="color: red"
                title="Upload Failed">
                mdi-block-helper
              </v-icon>
              <v-icon
                v-show="
                  props.item.isDuplicateOnServer && !props.item.isImported
                "
                style="color: green"
                title="Client already exists">
                mdi-cloud
              </v-icon>
            </td>
            <td>{{ props.item.client_name }}</td>
            <td>{{ props.item.contact_name }}</td>
            <td>{{ props.item.contact_phone }}</td>
            <td>{{ props.item.contact_email }}</td>
            <td>{{ props.item.address }}</td>
            <td>{{ props.item.city }}</td>
            <td>{{ props.item.state }}</td>
            <td>{{ props.item.zip_code }}</td>
            <td>{{ props.item.country }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar v-model="showStatusMessage" :timeout="10000">
      {{ uploadStatusMessage }}
      <v-btn color="blue" text @click="showStatusMessage = false">
        Close
      </v-btn>
    </v-snackbar>

    <progress-dialog
      v-model="processing"
      header="Processing"
      body="Processing" />
  </v-container>
</template>

<script>
  import papa from 'papaparse';
  import progressDialog from '@/components/helpers/ProgressDialog';
  import axios from 'axios';
  // services
  import Clients from '@/services/Clients.service.js';

  export default {
    name: 'UploadDataView',
    components: {
      progressDialog,
    },
    props: ['uuid'],
    data() {
      return {
        clients: [],
        search: '',
        tenant: undefined,
        file: null,
        fileContents: null,
        processing: false,
        showStatusMessage: false,
        uploadStatusMessage: '',
        genericErrorMessage:
          'Failed to upload your clients. Please check for errors and try again.',
        validate_file: [(v) => !!v || 'This is required'],
        showStatusDialog: false,
        fileUploaded: false,
        importComplete: false,
        fileUploading: false,
        countOfRecordsImported: 0,
        countOfRecordsUploaded: 0,
        headers: [
          {
            text: '',
            value: '',
          },
          {
            text: 'Client Name ID',
            align: 'left',
            sortable: true,
            value: 'number',
          },
          {
            text: 'Contact Name',
            align: 'left',
            sortable: true,
            value: 'client_name',
          },
          {
            text: 'Contact Phone',
            value: 'contact_phone',
          },
          {
            text: 'Contact Email',
            value: 'contact_email',
          },
          {
            text: 'Contact Address',
            value: 'address',
          },
          {
            text: 'City',
            value: 'city',
          },
          {
            text: 'State',
            value: 'state',
          },
          {
            text: 'Zip',
            value: 'zip_code',
          },
          {
            text: 'Country',
            value: '',
          },
        ],
        allowView: false,
        allowEdit: false,
        allowCreate: false,
      };
    },
    computed: {
      isFileUploaded() {
        return this.fileUploaded;
      },
      isFileImported() {
        return this.importComplete;
      },
      isFileUploading() {
        return this.fileUploading;
      },
    },
    watch: {
      uploadStatusMessage(message) {
        if (message) {
          this.showStatusMessage = true;
        }
      },
    },
    methods: {
      onFocus: function () {
        // hack to stop client list from checking for duplicates twice
        this.listenForChangeEvent = true;
      },
      onBlur: function () {
        this.listenForChangeEvent = false;
      },
      handleFile: function (fileData) {
        if (!fileData || this.isFileUploading || !this.listenForChangeEvent) {
          return;
        }
        this.fileContents = new File([fileData], 'uploadedFile', {
          type: fileData.type,
        });
        Object.assign(this.fileContents, fileData);
        this.uploadFile();
      },
      uploadFile: function () {
        if (this.isFileUploading) {
          return;
        }
        let self = this;
        self.fileUploading = true;
        papa.parse(self.fileContents, {
          header: true,
          worker: true,
          skipEmptyLines: true,
          complete: function (results) {
            self.fileUploading = false;
            self.fileUploaded = true;
            self.fileContents = results.data;
            self.fileContents.forEach((row) => {
              //style-triggering properties
              row.isDuplicate = false;
              row.isDuplicated = false;
              row.isDuplicateOnServer = false;
              row.isImported = false;
              row.importFailed = false;
            });
            self.clients = self.identifyDuplicatesInUpload(self.fileContents);
            self.identifyDuplicatesOnServer(self.clients);
          },
          error: function () {
            self.fileUploading = false;
          },
        });
      },
      identifyDuplicatesInUpload: function (records) {
        let recordsNew = records.map((row, idx) => {
          let recordsCopy = Array.from(records);
          delete recordsCopy[idx];
          recordsCopy.map((row2, idx2) => {
            if (
              JSON.stringify(recordsCopy[idx2]) == JSON.stringify(records[idx])
            ) {
              row.isDuplicated = true;
              row2.isDuplicate = true;
            }
            return row2;
          });
          return row;
        });
        return recordsNew;
      },
      async identifyDuplicatesOnServer() {
        let self = this;

        const contactEmails = self.clients.map((record) => {
          return record.contact_email;
        });

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // service returns each client name that is duplicate,
        //  or an empty array if none found
        axios({
          method: 'POST',
          url: '/service_clients/clients/bulk_import_duplicates_search',
          data: {
            emails: contactEmails,
            tenant_uuid: this.$auth.userProfile.tenant_uuid,
          },
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then(
            (result) => {
              if (result.data.error) {
                this.errorMessage = result.data.error.original.detail;
                // TODO: fix this use of $nextTick. It's probably a mistake
                // since it doesn't have a callback.
                // eslint-disable-next-line vue/valid-next-tick
                this.$nextTick();
                return;
              } else {
                result.data.clientEmailsOfDuplicates.forEach((email) => {
                  self.clients.forEach((client) => {
                    if (email == client.contact_email) {
                      client.isDuplicateOnServer = true;
                      return;
                    }
                  });
                });
              }
              let recordsCopy = Array.from(self.clients);
              self.countOfRecordsUploaded = recordsCopy.length;
              self.clients = recordsCopy;
            },
            (e) => {
              alert('Importing Clients failed on submission');
              console.log(e);
            }
          )
          .catch((error) => {
            console.log(error);
            alert('Error in service call - uploadData');
          });
      },
      deleteBadRecords: function () {
        this.clients = this.clients.filter(function (client) {
          return !client.isDuplicate && !client.isDuplicateOnServer;
        });
      },
      async importRecords() {
        if (!this.clients) {
          return false;
        }
        this.importComplete = false;
        this.processing = true;

        this.deleteBadRecords();

        let recordsToSubmit = this.clients.map((client) => {
          let clientCopy = Object.assign({}, client);
          clientCopy.tenant_uuid = this.$auth.userProfile.tenant_uuid;
          clientCopy.uuid = Clients.newClientUUID();
          clientCopy.client_status = 'Active';
          clientCopy.client_type = 'Business';
          return clientCopy;
        });

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();
        axios({
          method: 'POST',
          url: '/service_clients/clients/bulk_import',
          data: {
            clients: recordsToSubmit,
            tenant_uuid: this.$auth.userProfile.tenant_uuid,
          },
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then(
            function (result) {
              this.processing = false;
              this.importComplete = true;
              this.countOfRecordsImported =
                result.data.numberOfClientsImported || 0;
              if (result.data.error) {
                this.uploadStatusMessage = result.data.error.original.detail;
              } else {
                this.uploadStatusMessage = 'Your clients have been uploaded.';

                this.clients.forEach((client) => {
                  client.isImported = false;
                  client.importFailed = true;
                  client.isDuplicate = false;
                  client.isDuplicated = false;
                  client.isDuplicateOnServer = false;
                });

                let importedClients = result.data.clientsImported;
                importedClients.forEach((client) => {
                  this.clients.forEach((client2) => {
                    if (client.contact_email == client2.contact_email) {
                      client2.isImported = true;
                      delete client2['isDuplicate'];
                      delete client2['isDuplicated'];
                      delete client2['isDuplicateOnServer'];
                      delete client2['importFailed'];
                      return;
                    }
                  });
                });
              }
              let recordsCopy = Array.from(this.clients);
              this.clients = recordsCopy;
            }.bind(this),
            (e) => {
              this.processing = false;
              this.uploadStatusMessage = this.genericErrorMessage;
              console.log(e);
            }
          )
          .catch((error) => {
            this.processing = false;
            this.uploadStatusMessage = this.genericErrorMessage;
            console.log(error);
          });
      },
    },
  };
</script>

<style scoped lang="scss">
  .duplicate {
    background-color: #ffdcd4;
  }
  .duplicateOnServer {
    background-color: #ffdcd4;
  }
</style>
