<template>
  <v-tabs fixed-tabs>
    <!--     <v-tab key='gettingStarted'>
      Getting Started
    </v-tab> -->
    <v-tab key="uploadData"> Upload Your Clients </v-tab>
    <v-tab key="uploadJobSites"> Upload Job Sites </v-tab>
    <!--     <v-tab-item key='gettingStarted'>
      <getting-started></getting-started>
    </v-tab-item> -->
    <v-tab-item key="uploadData">
      <upload-data />
    </v-tab-item>
    <v-tab-item key="uploadJobSites">
      <upload-job-sites />
    </v-tab-item>
  </v-tabs>
</template>
<script>
  import UploadData from '@/components/tools/UploadClients';
  import UploadJobSites from '@/components/tools/UploadJobSites';
  //import GettingStarted from '@/components/tools/GettingStarted'

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Tools',
    components: {
      UploadData,
      UploadJobSites,
      //, GettingStarted
    },
    props: {},
    data() {
      return {};
    },
    computed: {},
    mounted() {},
    methods: {},
  };
</script>

<style scoped>
  v-tab-item: padding 10px;
</style>
