<template>
  <v-container>
    <v-toolbar flat color="white" class="mt-1">
      <v-toolbar-title>
        <span class="headline"> Upload Job Sites </span>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-title>
        <v-btn class="button-primary px-2">
          <v-icon>mdi-download</v-icon>
          <a
            style="color: white"
            href="../static/JOBS_sample_jobsites_upload.csv"
            download
            >Get Sample File</a
          >
        </v-btn>
      </v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <v-form>
          <v-file-input
            ref="fileInputRef"
            cols="12"
            label="File to Upload (.csv)"
            chips
            :success="isFileUploaded"
            @click:clear="
              () => {
                fileUploaded = false;
                targetFile = null;
              }
            "
            accept=".csv"
            v-model="targetFile" />
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          class="success px-2"
          @click="uploadJobSiteFile()"
          :disabled="!targetFile || fileUploaded">
          <v-icon> mdi-import </v-icon>
          Import Records
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="fileUploaded">
      <v-row dense class="subtitle-2"> Submitted: {{ countSubmitted }} </v-row>
      <v-row dense class="subtitle-2"> Imported: {{ countImported }} </v-row>
      <v-row dense class="subtitle-2">
        Failed: {{ countSubmitted - countImported }}
      </v-row>
      <v-list>
        <v-list-item v-for="(item, index) in errors" :key="index">
          {{ item }}
        </v-list-item>
      </v-list>
    </div>
  </v-container>
</template>

<script>
  import Papa from 'papaparse';

  // services
  import Clients from '@/services/Clients.service.js';

  export default {
    name: 'UploadJobSitesView',
    components: {},
    props: ['uuid'],
    data() {
      return {
        targetFile: null,
        jobsites: [],
        fileUploaded: false,
        countImported: 0,
        countSubmitted: 0,
        errors: [],
      };
    },
    computed: {
      isFileUploaded() {
        return this.fileUploaded;
      },
    },
    methods: {
      uploadJobSiteFile() {
        var that = this;
        this.jobsites = [];

        // parse in file content
        Papa.parse(this.targetFile, {
          // currently our example file has headers
          header: true,
          // skip empty lines
          skipEmptyLines: true,
          // row parsing stream
          step: function (row) {
            // ***
            // TODO: add row validation
            // ***

            // add new jobsite to processing array
            that.jobsites.push({
              // row from csv
              ...row.data,
              // new unique jobsite uuid
              uuid: Clients.newJobSiteUUID(),
              // tenant uuid
              tenant_uuid: that.$auth.userProfile.tenant_uuid,
            });
          },
          // after parsing in rows
          complete: async function () {
            // Get the access token from the auth wrapper
            const accessToken = await that.$auth.getTokenSilently();

            // send data to middleware
            var result = await Clients.createJobSitesBulk(
              {jobsites: that.jobsites},
              accessToken
            );

            if (result) {
              console.log(result);
              // ***
              // TODO: error response parsing and user feedback
              // ***
              that.countImported = result.imported;
              that.countSubmitted = result.submitted;
              that.errors = result.errors;
            }

            that.fileUploaded = true;
          },
          // error handling
          error: function (err) {
            console.log('File parsing error:', err);
          },
        });
      },
    },
  };
</script>

<style scoped lang="scss"></style>
