import { render, staticRenderFns } from "./UploadJobSites.vue?vue&type=template&id=22755ea4&scoped=true&"
import script from "./UploadJobSites.vue?vue&type=script&lang=js&"
export * from "./UploadJobSites.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22755ea4",
  null
  
)

export default component.exports